<script lang="ts">
    import Citations from './components/Citations.svelte';

    export let citationsAPIEndpoint: string = "https://static.nonprod.ssrn.com/cfc/abstract/abstract.cfc?method=getCitedbys";
    export let paperId: number = 463226;
    export let userId: number = 4550146;
    export let fetchAll: boolean = false;
</script>

<Citations citationsAPIEndpoint={citationsAPIEndpoint} paperId={paperId} userId={userId} fetchAll={fetchAll}/>
