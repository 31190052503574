import App from './App.svelte';
let appTag = document.getElementById('paper-citations-widget');
const app = new App({
    target: appTag,
    props: {
        citationsAPIEndpoint: appTag.getAttribute("data-citations-api-url"),
        paperId: appTag.getAttribute("data-paper-id"),
        userId: appTag.getAttribute('data-user-id'),
        fetchAll: (appTag.getAttribute('data-fetch-all') === "true")
    }
});
export default app;
