<script lang="ts">
    import SSRNThrobber from "./SSRNThrobber.svelte";
    import CitationItem from "./CitationItem.svelte";

    export let citationsAPIEndpoint: string = "https://static.nonprod.ssrn.com/cfc/abstract/abstract.cfc?method=getCitedbys";
    export let paperId: number = 463226;
    export let userId: number = 4550146;
    export let fetchAll: boolean = false;

    let rootDomain = citationsAPIEndpoint.split("/")[2]; // e.g. static.nonprod.ssrn.com or static.ssrn.com
    const getCitationsEndpoint: string = citationsAPIEndpoint + '&ab_id=' + paperId;
    let getPapersInLibraryEndpoint: string = "https://" + rootDomain + "/cfc/webservices/briefcaseServices.cfc" +
        "?method=getBriefcaseContents&user_id=" + userId;
    let addOrRemovePapersFromLibraryEndpoint: string = "https://" + rootDomain + "/cfc/webservices/briefcaseServices.cfc" +
        "?user_id=" + userId + "&method=";
    let myPapersUrl = "https://hq.ssrn.com/Library/myLibrary.cfm";

    let number_of_citations: number = 0;
    let showNumber: number = 0;
    let firstCitation: number = 1;
    let citations: Array<Object> = [];
    let isSearching: boolean = false;
    let hasFetched: boolean = false;
    let papersInMyLibrary: Array<Object> = []


    const fetchCitations = async () => {
        isSearching = true;
        try {
            // console.log("Citations Endpoint: " + getCitationsEndpoint);
            let returnedCitations = await fetch(getCitationsEndpoint).then(response => {
                isSearching = false;
                if (!response.ok) {
                    throw Error("Bad response code");
                } else {
                    return response.json();
                }
            });
            // console.log("Extracted data = " + JSON.stringify(returnedCitations));
            number_of_citations = returnedCitations["total_items"];
            citations = "items" in returnedCitations ? returnedCitations["items"] : [];
        } catch (error) {
            isSearching = false;
            console.log("Error contacting API." + getCitationsEndpoint);
        }
        hasFetched = true;
        if (fetchAll === true) showNumber = number_of_citations;
    }

    const showNext50 = () => {
        showNumber += 50;
    }

    const thisPaperIsInMyLibrary = (abstractId: number) => {
        return papersInMyLibrary.includes(abstractId);
    }

    const getPapersInMyLibrary = async () => {
        try {
            // console.log("getPapersInLibrary Endpoint: " + getPapersInLibraryEndpoint);
            let returnedPapersInLibrary = await fetch(getPapersInLibraryEndpoint).then(response => {
                if (!response.ok) {
                    throw Error("Bad response code from get my library call");
                } else {
                    return response.json();
                }
            });
            // console.log("Extracted my library data = " + JSON.stringify(returnedPapersInLibrary));
            papersInMyLibrary = "ABSTRACTS" in returnedPapersInLibrary ? returnedPapersInLibrary["ABSTRACTS"] : [];
        } catch (error) {
            console.log("Error contacting my library API. " + getPapersInLibraryEndpoint);
        }
    }

    const addOrRemoveThisPaperFromMyLibrary = async (abstractId: number) => {
        // console.log("call to addOrRemoveThisPaperFromMyLibrary for : " + abstractId);
        let message: string = "The article (" + abstractId + ") has been removed from your library.";
        let thisPapersLibraryEndpoint = addOrRemovePapersFromLibraryEndpoint;
        if (thisPaperIsInMyLibrary(abstractId)) {
            thisPapersLibraryEndpoint += "removeFavPaper&ab_id=" + abstractId;
        } else {
            thisPapersLibraryEndpoint += "addFavPaper&ab_id=" + abstractId;
            message = "The article (" + abstractId + ") has been added to your library.";
        }
        if (userId == 1) {
            console.log("user not signed in - taking them to their library via log in to add this paper.");
            window.location.href = myPapersUrl + "?abid=" + abstractId;
        } else {
            try {
                // console.log("thisPapersLibraryEndpoint : " + thisPapersLibraryEndpoint);
                let addOrRemovePapers = await fetch(thisPapersLibraryEndpoint).then(response => {
                    if (!response.ok) {
                        throw Error("Bad response code from post to my library");
                    } else {
                        return response.json();
                    }
                });
                // console.log("returned my library response = " + JSON.stringify(addOrRemovePapers));
                papersInMyLibrary = "SUCCESS" in addOrRemovePapers ? addOrRemovePapers["SUCCESS"] : [];
                alert(message);
                citations = [];
                getPapersInMyLibrary().then(fetchCitations);
                // console.log("papers In My Library now " + papersInMyLibrary);
            } catch (error) {
                console.log("Error contacting my library endpoint. " + getPapersInLibraryEndpoint);
            }
        }
    }

    const addToCart = async (abstractId: number, cost: number) => {
        // console.log("call to addToCart with ab=" + abstractId + " and cost=" + cost);
        let paperDomain = rootDomain.replace("static", "papers");
        let addToCartUrl = "https://" + paperDomain + "/sol3/ShoppingCart.cfm?funct=add&txtQuantity=1&txtTotalPrice=" +
            cost + "&txtAbID=" + abstractId + "&checkout=yes";
        // console.log("cart url = " + addToCartUrl);
        let addToCartCall = await fetch(addToCartUrl).then(response => {
            if (!response.ok) {
                throw Error("Bad response code from add to cart call");
            } else {
                return response.json();
            }
        });
        // console.log("returned add to cart response = " + JSON.stringify(addToCartCall));
        alert("The paper (" + abstractId + ") has been added to your cart.");
    }

    getPapersInMyLibrary().then(fetchCitations);
</script>

<div id="citations-widget" data-testid="citations-widget">
    <h3>{number_of_citations} Citations</h3>
    {#if !hasFetched}
        <button class="button button-fetch" on:click={fetchCitations}
                title="Fetch initial citations for this paper">Fetch Citations
        </button>
    {/if}
    {#if isSearching}
        <SSRNThrobber/>
    {:else}
        <ol class="citations-list-result" start="{firstCitation}">
            {#each citations as citation, index (index)}
                {#if citation && index < (4 + showNumber)}
                    <CitationItem citationData="{citation}"
                                  inLibrary="{thisPaperIsInMyLibrary(citation.ssrn_abstract_id)}"
                                  on:addOrRemove="{addOrRemoveThisPaperFromMyLibrary(citation.ssrn_abstract_id)}"
                                  on:addToCart="{addToCart(citation.ssrn_abstract_id, citation.iCost)}"/>
                {/if}
            {/each}
        </ol>

        {#if citations && citations.length > showNumber}
            <button class="button button-load-more" on:click={showNext50}
                    title="Fetch more citations for this paper">Load more
            </button>
        {/if}
    {/if}
</div>

<style>
    h3 {
        font-size: 24px;
        padding: 20px;
        font-weight: bold;
        color: #007499;
        border-bottom: 1px solid #d8dde6;
    }

    .button {
        display: block;
        border: solid 1px #979797;
        font-size: 16px;
        padding: 5px 20px;
        color: #4a4a4a;
        background: #ffffff;
    }

    .button-load-more {
        margin: 20px auto;
    }
</style>